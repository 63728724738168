const parlors = [
  {
    id: 1,
    name: "AMO",
    address: "Jalan Batu Bolong, Canggu, Bali",
    rating: 4.4,
    reviews: 907,
    services: ["Massage", "Restaurant", "Sauna", "Icebath"],
    images: ['/images/amospa_1.jpg', '/images/amospa_2.jpg', '/images/amospa_3.jpg', '/images/amospa_4.jpg'],
    category: "Luxurious",
    number: "+19296254982",
    location: "Canggu",
    workingHours: "9:00 AM - 9:00 PM"
  },
  {
    id: 2,
    name: "Lotus Echo",
    address: "Jl. Batu Mejan Canggu, Canggu, Kec. Kuta Utara, Kabupaten Badung, Bali 80351, Indonesia",
    rating: 4.5,
    reviews: 632,
    services: ["Balinese Massage", "Women owned", "Center location "],
    images: ['/images/lotus_1.jpg', '/images/lotus_2.jpg','/images/lotus_3.jpg'],
    category: "Affordable",
    number: "+19296254982",
    location: "Canggu",
    workingHours: "9:00 AM - 9:00 PM"
  },
  {
    id: 6,
  name: "Bliss Sanctuary For Women",
  address: "Secret location",
  rating: 4.9,
  reviews: 170,
  services: ["Spa Experience", "Wellness Bathing", "Retreats"],
  images: ['/images/BlissSanctuaryForWomen_1.jpg', '/images/BlissSanctuaryForWomen_2.jpg', '/images/BlissSanctuaryForWomen_3.jpg'],
  category: "Women Only",
  number: "+19296254982",
  location: "Canggu",
  workingHours: "9:00 AM - 9:00 PM"
},

{
  id: 4,
  name: "ECO",
  address: "Jalan Batu Bolong, Canggu, Bali",
  rating: 4.5,
  reviews: 150,
  services: ["Massage", "Facial", "Pedicure"],
  images: ['/images/EcoSpa_1.jpg', '/images/EcoSpa_2.jpg', '/images/EcoSpa_3.jpg'],
  category: "Luxurious",
  number: "81234567890",
  location: "Canggu",
  workingHours: "9:00 AM - 9:00 PM"
},

{
  id: 7,
  name: "Esthetic",
  address: "Wisata Plaza, Jl. Raya Taman, Canggu, Kec. Kuta Utara, Kabupaten Badung, Bali 80361",
  rating: 4.4,
  reviews: 193,
  services: ["Full body Massage", "Nails", "Skin/hair-care"],
  images: ['/images/EstheticSalon&BeautyCare_1.jpg', '/images/EstheticSalon&BeautyCare_2.jpg', '/images/EstheticSalon&BeautyCare_3.jpg'],
  category: "Massage+Nails",
  number: "81234567890",
  location: "Canggu",
  workingHours: "9:00 AM - 9:00 PM"
},
{
  id: 9,
  name: "SHOSAN",
  address: "Jl. Dalem Lingsir, Pererenan, Kec. Mengwi, Kabupaten Badung, Bali 80351",
  rating: 4.8,
  reviews: 74,
  services: ["Head massage", "Hair Therapy", "Massage & Body Work"],
  images: ['/images/Shosan_1.jpg', '/images/Shosan_2.jpg', '/images/Shosan_3.jpg'],
  category: "Head Spa",
  number: "81234567890",
  location: "Canggu",
  workingHours: "9:00 AM - 9:00 PM",
},
{
  id: 10,
  name: "Ki Ashiatsu ",
  address: "Jl. Sunset Road No.151, Kerobokan Kelod, Kec. Kuta Utara, Kabupaten Badung, Bali 80361",
  rating: 4.5,
  reviews: 1130,
  services: ["Japanese", "Shiatsu", "Pain relief"],
  images: ['/images/image1.jpg'],
  category: "Deep Tissue",
  number: "81234567890",
  location: "Canggu",
  workingHours: "9:00 AM - 9:00 PM",
},
{
  "id": 11,
  "name": "Samadi",
  "address": "Jalan Padang Linjong 39, Echo Beach, Canggu, Bali 80361, Indonesia",
  "rating": 4.5,
  "reviews": 129,
  "services": ["Hypnotherapy", "Acupuncture", "Detox"],
  "images": ["/images/Samadi_1.jpg", "/images/Samadi_2.jpg", "/images/Samadi_3.jpg"],
  "category": "Holistic",
  "number": "81234567890",
  "location": "Canggu",
  "workingHours": "7:00 AM - 9:00 PM"
  },
{
  "id": 12,
  "name": "Chillax",
  "address": "Jl. Kayu Tulang No.82, Canggu, Kec. Kuta Utara, Kabupaten Badung, Bali 80361, Indonesia",
  "rating": 4.5,
  "reviews": 76,
  "services": ["Trendy Vibes", "Modern Therapies", "Youthful Energy"],
  "images": ["/images/Chillax_1.jpg", "/images/Chillax_2.jpg","/images/Chillax_3.jpg"],
  "category": "Cool",
  "number": "81234567890",
  "location": "Canggu",
  "workingHours": "10:00 AM - 6:00 PM"
  },
{
  "id": 13,
  "name": "Tender Touch",
  "address": "Jl. Teratai, Canggu, Bali 80361, Indonesia",
  "rating": 4.7,
  "reviews": 54,
  "services": ["Lymphatic Drainage", "Cellulite Reduction", "Natural Oils"],
  "images": ["/images/image4.jpg"],
  "category": "Body Sculpting",
  "number": "81234567890",
  "location": "Canggu",
  "workingHours": "9:00 AM - 8:00 PM"
  },
{
  "id": 14,
  "name": "Josun",
  "address": "Canggu, North Kuta, Bali, Indonesia",
  "rating": 4.8,
  "reviews": 33,
  "services": ["Korean Skincare", "Traditional Korean Massage", "Herbal Treatments"],
  "images": ["/images/Josun_1.jpg", "/images/Josun_2.jpg", "/images/Josun_3.jpg"],
  "category": "Korean",
  "number": "81234567890",
  "location": "Canggu",
  "workingHours": "10:00 AM - 9:00 PM"
  },
{
  "id": 15,
  "name": "Goldust",
  "address": "Jl. Batu Bolong No.66, Canggu, Kec. Kuta Utara, Kabupaten Badung, Bali 80361, Indonesia",
  "rating": 4.9,
  "reviews": 112,
  "services": ["24K Gold Facial", "Signature Massage", "Body Treatments"],
  "images": ["/images/Golddust_1.jpg", "/images/Golddust_2.jpg", "/images/Golddust_3.jpg"],
  "category": "Gold Facial",
  "number": "81234567890",
  "location": "Canggu",
  "workingHours": "9:00 AM - 9:00 PM"
  },
{
  "id": 16,
  "name": "Koya",
  "address": "Tegalgundul square, Jl. Pantai Berawa, Tibubeneng, Kec. Kuta Utara, Kabupaten Badung, Bali 80361, Indonesia",
  "rating": 4.8,
  "reviews": 85,
  "services": ["Traditional Thai Massage", "Thai Stretching Therapy", "Herbal Thai Compress"],
  "images": ["/images/Koya_1.jpg", "/images/Koya_2.jpg", "/images/Koya_3.jpg"],
  "category": "Thai Massage",
  "number": "81234567890",
  "location": "Canggu",
  "workingHours": "9:00 AM - 11:00 PM"
  },
{
  "id": 17,
  "name": "Shortcut Canggu Spa",
  "address": "Jl. Anggrek, Tibubeneng, Canggu, Kec. Kuta Utara, Kabupaten Badung, Bali 80361, Indonesia",
  "rating": 4.9,
  "reviews": 102,
  "services": ["Full Body Massage", "Hot Stone Massage", "Flower Facial"],
  "images": ["/images/Shortcut_1.jpg", "/images/Shortcut_2.jpg", "/images/Shortcut_3.jpg"],
  "category": "Most Popular",
  "number": "81234567890",
  "location": "Canggu",
  "workingHours": "9:00 AM - 9:00 PM"
  },
{
  "id": 18,
  "name": "Flex Bali",
  "address": "Jl. Pantai Berawa No. 13, Tibubeneng, Kuta Utara, Kabupaten Badung, Bali 80361, Indonesia",
  "rating": 4.9,
  "reviews": 58,
  "services": ["Assisted Stretching", "Flexibility Improvement", "Relaxation & Stretch"],
  "images": ["/images/Flexbali_1.jpg", "/images/Flexbali_2.jpg", "/images/Flexbali_3.jpg"],
  "category": "Assisted Stretching",
  "number": "81234567890",
  "location": "Canggu",
  "workingHours": "7:00 AM - 9:00 PM"
  },
{
  "id": 19,
  "name": "It's Face Day",
  "address": "Jl. Pantai Berawa No.14, Berawa, Canggu, Bali 80361, Indonesia",
  "rating": 4.8,
  "reviews": 110,
  "services": ["Functional Facials", "Facial Scanning", "Sculpting Face Yoga"],
  "images": ["/images/Faceday_1.jpg", "/images/Faceday_2.jpg", "/images/Faceday_3.jpg"],
  "category": "Facial Bar",
  "number": "81234567890",
  "location": "Canggu",
  "workingHours": "10:00 AM - 7:00 PM"
  },
{
  "id": 20,
  "name": "COMO Uma",
  "address": "Jalan Pantai Batu Mejan, Echo Beach, Canggu, Bali 80361, Indonesia",
  "rating": 4.7,
  "reviews": 305,
  "services": ["Asian-inspired Therapies", "COMO Shambhala Retreat", "Fitness and Wellness Activities"],
  "images": ["/images/Como_1.jpg", "/images/Como_2.jpg", "/images/Como_3.jpg"],
  "category": "Luxury Wellness Retreat",
  "number": "81234567890",
  "location": "Canggu",
  "workingHours": "10:00 AM - 7:00 PM"
}
]

export default parlors;
