import React, { useState, useEffect } from 'react';
import { FaWhatsapp, FaMapMarkerAlt } from 'react-icons/fa';
import { IoClose, IoChevronForward, IoChevronBack } from 'react-icons/io5';
import './Modal.css';

const fallbackImageUrl = 'https://via.placeholder.com/300x200?text=Image+Not+Available';

const getImageSrc = (imagePath) => {
  return `${process.env.PUBLIC_URL}${imagePath}`;
};

function Modal({ parlor, onClose }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (parlor && parlor.images) {
      console.log('Parlor images:', parlor.images);
      setImages(parlor.images);
      setCurrentImageIndex(0); // Reset to first image when parlor changes
    }
  }, [parlor]);

  if (!parlor) return null;

  const openWhatsApp = () => {
    const message = `Hello, I'm interested to book at ${parlor.name}. Im coming from www.balispa.me`;
    const encodedMessage = encodeURIComponent(message);
    const url = `https://wa.me/${parlor.number}?text=${encodedMessage}`;
    window.open(url, '_blank');
  };

  const openInMaps = () => {
    const encodedAddress = encodeURIComponent(parlor.address);
    const url = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
    window.open(url, '_blank');
  };

  const handleImageError = (e) => {
    console.error('Image failed to load:', e.target.src);
    e.target.src = fallbackImageUrl;
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  console.log('Current image index:', currentImageIndex);

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          <IoClose />
        </button>
        <h2>{parlor.name}</h2>
        <div className="address-container">
          <p className="address">{parlor.address}</p>
          <button onClick={openInMaps} className="open-maps-button">
            <FaMapMarkerAlt /> Open in Maps
          </button>
        </div>
        <div className="rating">
          <span className="stars">{'★'.repeat(Math.floor(parlor.rating))}</span>
          <span className="rating-number">{parlor.rating}</span>
          <span className="reviews">({parlor.reviews} reviews)</span>
        </div>
        
        <div className="section">
          <h3>Working Hours</h3>
          <p>{parlor.workingHours}</p>
        </div>

        <div className="section">
          <h3>Most Popular Services</h3>
          <ul className="services-list">
            {parlor.services.map((service, index) => (
              <li key={index}>{service}</li>
            ))}
          </ul>
        </div>

        <div className="section">
          <h3>Gallery</h3>
          <div className="gallery-container">
            <button className="gallery-nav prev" onClick={prevImage} disabled={images.length <= 1}>
              <IoChevronBack />
            </button>
            <div className="gallery">
              {images.length > 0 ? (
                <img 
                  src={getImageSrc(images[currentImageIndex])}
                  alt={`${parlor.name} ${currentImageIndex + 1}`} 
                  onError={handleImageError}
                />
              ) : (
                <p>No images available</p>
              )}
            </div>
            <button className="gallery-nav next" onClick={nextImage} disabled={images.length <= 1}>
              <IoChevronForward />
            </button>
          </div>
          <p className="image-counter">
            Image {currentImageIndex + 1} of {images.length}
          </p>
        </div>

        <button onClick={openWhatsApp} className="book-now-button">
          <FaWhatsapp /> Book now
        </button>
      </div>
    </div>
  );
}

export default Modal;