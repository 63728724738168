import './App.css';
import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import parlors from './Parlors';

// Fallback image URL
const fallbackImageUrl = 'https://via.placeholder.com/300x200?text=Image+Not+Available';

// Get image function
const getImageSrc = async (imagePath) => {
  const publicUrl = process.env.PUBLIC_URL;
  const fullPath = `${publicUrl}${imagePath}`;

  try {
    const response = await fetch(fullPath);
    if (response.ok) {
      return fullPath;
    }
  } catch (error) {
    console.error(`Error fetching image ${fullPath}:`, error);
  }

  // If the image fetch fails, return the fallback image URL
  return `${publicUrl}/images/fallback.jpg`;
};
// Helper function to check if a parlor is currently open
const isOpen = (workingHours) => {
  const [start, end] = workingHours.split(' - ').map(time => {
    const [hours, minutes, period] = time.match(/(\d+):(\d+) (\w+)/).slice(1);
    return (parseInt(hours) % 12 + (period === 'PM' ? 12 : 0)) * 60 + parseInt(minutes);
  });
  
  const now = new Date();
  const currentMinutes = now.getHours() * 60 + now.getMinutes();
  
  return currentMinutes >= start && currentMinutes <= end;
};

function App() {
  const [selectedParlor, setSelectedParlor] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState('Canggu');
  const [sortByHighestRating, setSortByHighestRating] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filteredParlors, setFilteredParlors] = useState([]);
  const [parlorImageUrls, setParlorImageUrls] = useState({});

  const locations = ['Canggu', 'Ubud', 'Uluwatu', 'Kuta', 'Sanur'];


  useEffect(() => {
    const shuffledParlors = parlors
      .map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  
    let filtered = shuffledParlors.filter(parlor =>
      parlor.location === selectedLocation &&
      parlor.name.toLowerCase().includes(searchKeyword.toLowerCase())
    );
  
    if (sortByHighestRating) {
      filtered.sort((a, b) => b.rating - a.rating);
    } else {
      filtered.sort((a, b) => a.rating - b.rating);
    }
  
    setFilteredParlors(filtered);
  }, [selectedLocation, sortByHighestRating, searchKeyword]);

  useEffect(() => {
    const fetchImageUrls = async () => {
      const updatedImageUrls = {};
      for (const parlor of filteredParlors) {
        const imageUrl = await getImageSrc(parlor.images[0]);
        updatedImageUrls[parlor.id] = imageUrl;
      }
      setParlorImageUrls(updatedImageUrls);
    };

    fetchImageUrls();
  }, [filteredParlors]);

  const handleImageError = (e, parlorName) => {
    console.error(`Image failed to load for ${parlorName}:`, e.target.src);
    e.target.src = fallbackImageUrl;
  };

  return (
    <div className="App">
      <h1>Bali's Best Handpicked Spas</h1>
      <div className="location-tabs">
        {locations.map(location => (
          <button
            key={location}
            className={location === selectedLocation ? 'active' : ''}
            onClick={() => setSelectedLocation(location)}
          >
            {location}
          </button>
        ))}
      </div>
      <div className="filter-options">
        <label className="checkbox-wrapper-2">
          <input
            type="checkbox"
            className="ikxBAC"
            checked={sortByHighestRating}
            onChange={(e) => setSortByHighestRating(e.target.checked)}
          />
          <span>Highest rated first</span>
        </label>
      </div>
      <div className="search-box">
        <input
          type="text"
          placeholder="Search"
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
        />
      </div>
      <div className="parlor-grid">
        {filteredParlors.map(parlor => (
          <div key={parlor.id} className="parlor-card" onClick={() => setSelectedParlor(parlor)}>
            <img
              src={parlorImageUrls[parlor.id] || fallbackImageUrl}
              alt={parlor.name}
              onError={(e) => handleImageError(e, parlor.name)}
              onLoad={() => console.log('Image loaded successfully:', parlor.name)}
            />
            <div className="parlor-info">
              <h2>{parlor.name}</h2>
              <div className="rating">
                <span className="stars">{parlor.rating} ★</span>
                <span className="reviews">({parlor.reviews})</span>
              </div>
{/*               <p className="address">{parlor.address}</p>
 */}              <div className="category">{parlor.category}</div>
            </div>
            <div className={`open-status ${isOpen(parlor.workingHours) ? 'open' : 'closed'}`}>
              {isOpen(parlor.workingHours) ? 'Open Now' : 'Closed'}
            </div>
          </div>
        ))}
      </div>

      <Modal parlor={selectedParlor} onClose={() => setSelectedParlor(null)} />
    </div>
  );
}

export default App;